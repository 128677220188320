import { emojisplosion } from "emojisplosion";

function fire(x, y) {
  emojisplosion({
    emojiCount: 1,
    emojis: ["🍍"],
    physics: {
      initialVelocities: {
        rotation: {
          max: 20,
          min: -14,
        },
      },
      rotationDecelaration: 1.01,
    },
    position: () => ({
      x,
      y,
    }),
    process(element) {
      element.classList.add("zhora");
    },
  });
}

export function init() {
  document.addEventListener("mousemove", (event) => {
    window.requestAnimationFrame(() => {
      fire(event.clientX, event.clientY);
    });
  });
}
